import React from 'react';
import ReactDOM from 'react-dom';
import { Provider, ErrorBoundary } from '@rollbar/react';
import './variables.scss'
import './index.css';
import './style/styleSheet.css'
// import 'node_modules/bootstrap/scss/bootstrap';
import App from './App';
import * as serviceWorker from './serviceWorker';

const rollbarConfig = {
  accessToken: 'b1670d74e68e4d0b9b7a569b2cbc8ac4',
  environment: 'testenv',
}

ReactDOM.render(
  <Provider config={rollbarConfig}>
    <ErrorBoundary>
      <React.StrictMode>
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <App />
      </React.StrictMode>
    </ErrorBoundary>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
