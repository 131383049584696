import { useState, useEffect } from "react"
import uuid from 'react-uuid'
import LoginService from "./../API/services/LoginService";

export const getName = () => {
  if (localStorage.getItem("token")) {
    const today = new Date()
    const expiresAt = Date.parse(localStorage.getItem("expiresAt"))
    // if(today > expiresAt) { close() }
    const namesArray = localStorage.getItem("name").split(" ");
    return `${capitalizeWords(namesArray).join(' ')}`;
  }
};

export const toLogin = () => {
  window.location.href = `https://login.hbt-api.com/auth/login?return_url=${window.location.origin.toString()}&callback_url=${window.location.origin.toString()}/login&uuid=${uuid()}`
};

export const authenticateUser = (module) => {
  localStorage.setItem("module", module);
  if(localStorage.getItem("token") || window.location.pathname.includes('documentPublicPackingListReadOnly')){
    console.log('Access Granted')
  }else{
    getName(toLogin())
  }
}

// Validate Token
//
// Validates user token and user permission depending on the module.
// If token is valid, user is authenticated.
// @param module [string] - The module to validate the token against.
// @param token [string] - The token to validate.
// @returns [boolean] - True if token is valid, false otherwise.
export const validateToken = (token, module) => {
  LoginService.login({token: token}).then(response => {
    if(response.res && !response.res.data){
      localStorage.clear("token");
      authenticateUser(module);
    }
    else{
      validateRole()
    }
  })
  
}

// Validate Role
//
// Validate user pressence of warehouse and role
// If user has warehouse and role, does nothing.
// If user has no warehouse or invalid role, redirects to login.
// @returns [Null]
export const validateRole = () => {
  if(localStorage.getItem('warehouse') == 'NO APLICA' || !localStorage.getItem('warehouse')) close('Landing', 'no_warehouse')
  if(['none', 'basic'].includes(localStorage.getItem('role'))) close('Landing', 'permission_denied')
}

export const getWarehouse = () => {
  if (
    localStorage.getItem("warehouse") !== null 
  ) {
    const warehouse = localStorage.getItem("warehouse")
    return warehouse;
  } else {
    return '';
  }
}

export const getEmail = () => {
  if (
    localStorage.getItem("email") !== null 
  ) {
    const email = localStorage.getItem("email")
    return email;
  } else {
    return '';
  }
}

export const close = (module, error = null) => {
  localStorage.clear();
  localStorage.setItem("module", module);
  let error_parameter = error == null ?  `` : `&error=${error}`
  window.location.href = `https://login.hbt-api.com/auth/clear_cookies?redirect_url=${window.location.origin.toString()}${error_parameter}`
};

const capitalizeWords = (arr) => {
  return arr.map(element => {
    return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
  });
}
