/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-grid-system";
import { PreparationTransferSearchAction } from "../../redux/actions/documentDelivery.actions";
import { DocumentLocationAction, ChangePreparationLocationAction } from "../../redux/actions/documentPacking.actions";
import { connect } from "react-redux";
import { getParameterByName } from '../../helpers/getParamsUrl'
import moment from "moment";
import { useParams, useHistory, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar"
import DatePicker from "react-datepicker";
import Pagination from "@material-ui/lab/Pagination";
import { getWarehouse, authenticateUser, validateToken } from "../../utils/Commons"
import logo from "../../img/logo.png";
import close from "../../img/close2.svg"
import { ToastContainer, toast } from 'react-toastify';
import Modal from "react-bootstrap/Modal";


function PreparationTransfer({
  response,
  responseLocations,
  responseChange,
  ChangePreparationLocationAction,
  DocumentLocationAction,
  PreparationTransferSearchAction,
  loadingResponse,
  loadingResponseLocations,
  ModalLoadingResponse
}) {
  const [searcher, setSearcher] = useState("");
  const [locations, setLocations] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [locationFilter, setLocationFilter] = useState("");
  const [newLocation, setNewLocation] = useState("")
  const [maxPages, setMaxPages] = useState(1);
  const [deliveryDocuments, setDeliveryDocuments] = useState([])
  const [selectedDocuments, setSelectedDocuments] = useState([])
  const [isOpen, setIsOpen] = useState(false)
  const [startDate, setStartDate] = useState("");
  const [labelStartDate, setLabelStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [labelEndDate, setLabelEndDate] = useState("");

  const { id } = useParams();
  let history = useHistory();
  let locationUrl = useLocation();

  useEffect(() => {
    const page = getParameterByName('current_page', locationUrl.search) || 1
    const search = getParameterByName('search', locationUrl.search) || ''
    const location = getParameterByName('location', locationUrl.search) || ''
    let start_date = getParameterByName('start_date', locationUrl.search) || ''
    let end_date = getParameterByName('end_date', locationUrl.search) || ''
    if (page) setCurrentPage(+page);
    if (search) setSearcher(search);
    if (location) setLocationFilter(location);
    if (start_date && start_date != "") {
      setLabelStartDate(new Date(start_date))
      start_date = moment(start_date).format('YYYY/MM/DD')
      setStartDate(start_date);}
    if (end_date && end_date != "") {      
      setLabelEndDate(new Date(end_date))
      end_date = moment(end_date).format('YYYY/MM/DD')
      setEndDate(end_date)}
    PreparationTransferSearchAction({ search: search, filter: location,
                                     current_page: page, begin_date: startDate,
                                     end_date: endDate });
  }, [currentPage, searcher, locationFilter]);

  useEffect(() => {
    if (!response) return;
    setDeliveryDocuments(response.data);
    if (!response.meta) return;
    setMaxPages(+response.meta.max_pages)
  }, [response]);

  useEffect(() => {
    if (responseChange === undefined) {return}
    hideModal();
    cleanCheckboxes()
    setSelectedDocuments([])
    toast.success('Ubicación actualizada con éxito.')
    PreparationTransferSearchAction({ search: searcher, filter: locationFilter, current_page: currentPage,
                                     begin_date: startDate,
                                     end_date: endDate });
  }, [responseChange]);

  useEffect(() => {
    DocumentLocationAction({ id });
  }, [""]);

  // If user is already login in, verify if the user has permission in the Admin module.
  // If user is not loged in, validates its token and if user has permission in the Admin module.
  useEffect(() => {
    if(!localStorage.getItem("token")){
      authenticateUser('Admin')
    }else{
      validateToken(localStorage.getItem("token"),'Admin')
    }
  })

  const cleanCheckboxes = () => {
    const checkboxes = document.querySelectorAll('input[type=checkbox]')
    checkboxes.forEach((checkbox) => {
      checkbox.checked = false
    })
  }

  useEffect(() => {
    if (!responseLocations || !responseLocations.attributes || !responseLocations.attributes.locations) {
      return;
    } else {
      setLocations(responseLocations.attributes.locations);
    }
  }, [responseLocations]);

  const handleChange = () => {
    const searchInput = document.querySelector('#search_input').value
    setSearcher(searchInput)
    setCurrentPage(1)
    setSelectedDocuments([])
    cleanCheckboxes()
    history.push(`/preparationTransfer?search=${searchInput}&filter=${locationFilter}&current_page=1&start_date=${startDate}&end_date=${endDate}`);
  };

  const submitChangeLocation = () => {
    if(newLocation == "") {return}
    selectedDocuments.map((document) => {
      const payload = {id: document.id, location: newLocation}
      ChangePreparationLocationAction(payload);
    })
  }

  const pageChange = (event, value) => {
    setCurrentPage(value)
    setSelectedDocuments([])
    cleanCheckboxes()

    history.push(`/preparationTransfer?search=${searcher}&filter=${locationFilter}&current_page=${value}&start_date=${startDate}&end_date=${endDate}`);
  }

  const changeLocation = (event) => {
    setNewLocation(event.target.value)
  };

  const showModal = () => {
    setIsOpen(true);
  };

  const hideModal = () => {
    setIsOpen(false);
  };

  const parsedStartDate = (date) => {
    setLabelStartDate(date)
    setStartDate(moment(date).format('YYYY/MM/DD'))
  }

  const parsedEndDate = (date) => {
    setLabelEndDate(date)
    setEndDate(moment(date).format('YYYY/MM/DD'))
  }

  const dateStringParser = (text) => {
    let newDate = new Date(text)
    
    return moment(newDate).format('YYYY/MM/DD hh:mm:ss')
  }

  const handleClick = () => {
    filterType(startDate, endDate)
  }

  const filterType = (startDate, endDate) => {
    // const data = {
    //   begin_date: moment(startDate).format('YYYY/MM/DD'),
    //   end_date: moment(startDate).format('YYYY/MM/DD'),
    //   page: currentPage,
    // };
    PreparationTransferSearchAction({ search: searcher, filter: locationFilter, current_page: currentPage,
      begin_date: startDate,
      end_date: endDate });
  };

  const selectDocument = (id, document, checkbox) => {
    const checked = checkbox.target.checked;
    let newSelection = selectedDocuments
    if(checked){
      newSelection.push(document)
      
    }else{
      newSelection = newSelection.filter(item => item !== document)
    }
    let cleanDocuments = [...new Set(newSelection)]
    setSelectedDocuments(cleanDocuments)
  };

  const toAdmin = () => {
    history.push("/");
  };

  const handleOnIdle = event => {
    localStorage.clear();
    window.location.reload();
  }

  return (
    <div>
      <ToastContainer />
      {loadingResponse || loadingResponseLocations ?
        <div className='loading-modal'>
          <div className='loading-modal-logo'>
            <figure>
              <img className='rotation-zoom' src={logo} alt="hbt logo" />
            </figure>
          </div>
        </div>
        :
        null
      }
      <Navbar module="Admin" title="Admin" navBarName="Traspaso de preparaciones" />
      <Container className="body-height">
        <div className='row pt-4'>
          <div className='col-4'>
          </div>
          <div className='col-4 text-center'>
            <label className="title text-center">Preparaciones por Entregar </label><br />
            <label className="subtitle">Bodega {getWarehouse()}</label>
          </div>
        </div>
        <Row>
          <Col sm={12}>
            <div className="row">
            <div className='col-2 pr-1 pl-1'>
                <label>Desde</label>
                <DatePicker
                  className="inputCalendar form-control"
                  placeholderText="Fecha Inicio"
                  selected={labelStartDate}
                  dateFormat="yyyy-dd-MM"
                  onChange={(date) => {parsedStartDate(date)}}
                />
                </div>
              <div className='col-2 pr-1 pl-1'> 
                <label>Hasta</label>
                <DatePicker
                  className="inputCalendar form-control"
                  placeholderText="Fecha Fin"
                  selected={labelEndDate}
                  dateFormat="yyyy-dd-MM"
                  onChange={(date) => {parsedEndDate(date)}}
                />
              </div>
              <div className='col-2 d-flex' style={{ alignItems: "flex-end" }}>
                <button
                  className="btn btn-primary"
                  type="submit"
                  appearance="primary"
                  onClick={() => handleClick()}
                >
                  Filtrar
                </button></div>
              <div className="col-6">
                <div className="">
                  <label>Buscador</label>
                  <input
                    className="form-control"
                    type="text"
                    id="search_input"
                    placeholder="Buscar  por número de documento, número de preparación, tipo de documento o ubicación"
                    onKeyDown={(e) => (e.key === "Enter" ? handleChange() : null)}
                  />
                </div>
              </div>
            
            </div>
              
          </Col>
        </Row>
        <Row className='pt-4'>
          <Col sm={4}></Col>
          <Col sm={4}>
            <Pagination count={maxPages} page={currentPage} onChange={pageChange} color="primary" />
          </Col>
          <Col sm={4} className='text-right'>
            <button className='btn btn-primary' onClick={showModal}>Traspaso</button>
          </Col>
        </Row>
        <div className="listData">
          <table className="tableList">
            <thead>
              <tr>
                <td></td>
                <td>Ítem</td>
                <td>Nro. Documento Origen</td>
                <td>Tipo Documento Origen</td>
                <td>Nro. De Preparación</td>
                <td>Fecha de embultado</td>
                <td>Ubicación De Preparación</td>
                <td>Bulto</td>
              </tr>
            </thead>
            {deliveryDocuments && Number(deliveryDocuments.length > 0) ? (
              deliveryDocuments.map((item, index) => {
                return (
                  <tbody key={item + index}>
                    <tr key={index} >
                      <td><label for={index+"checkbox"}><input id={index+"checkbox"} type='checkbox' onClick={(e) => {selectDocument(item.attributes.id, item.attributes, e)}} /></label></td>
                      <td><label for={index+"checkbox"}>{(currentPage - 1) * 20 + index + 1}</label></td>
                      <td><label for={index+"checkbox"}>{item.attributes.document_number}</label></td>
                      <td><label for={index+"checkbox"}>{item.attributes.document_type}</label></td>
                      <td><label for={index+"checkbox"}>{item.attributes.preparation_number}</label></td>
                      <td><label for={index+"checkbox"}>{dateStringParser(item.attributes.packed_at)}</label></td>
                      <td><label for={index+"checkbox"}>{item.attributes.warehouse_location}</label></td>
                      <td>
                      <label for={index+"checkbox"}>{item.attributes.order_packages === "yes" ? "S" : "N"}</label>
                      </td>
                    </tr>
                  </tbody>
                );
              })
            ) : null}
          </table>
        </div>
        <Modal show={isOpen} onHide={hideModal} size='md'>
          <Modal.Header className="d-block"><div className="row">
            <div className="col-10">
              <h4>Traspaso de Preparaciones</h4>
            </div>
            <div className="col-2 text-right">
              <img className="close-button" src={close} alt="close" onClick={hideModal}/>
            </div> 
          </div></Modal.Header>
          <Modal.Body>
          {ModalLoadingResponse ?

            (<div className="text-center">
              <h2>Cargando...</h2>
              <figure>
                <img className='rotation-zoom' src={logo} alt="hbt logo" />
              </figure>
            </div>
            )

            :
            (<div className='row'>
            <div className='col-12'>
              <>
                <h5 className='modal-subtitle'>Preparaciones seleccionadas</h5>
                <table className="table no-border">
                  <tr>
                    <td>Número</td>
                    <td>Documento</td>
                    <td>Ubicación actual</td>
                  </tr>
                  {selectedDocuments ? (selectedDocuments.map((document) => {
                  const option = <tr>
                    <td>{document.document_number}</td> 
                    <td>{document.document_type} </td>
                    <td>{document.warehouse_location}</td>
                    </tr>
                  return option;
                })) : null}
                </table>
                
              </>
            </div>
            <div className='col-12'>
              <p className='mr-2'>Escoger nueva ubicación</p>
                <select
                  className="form-control"
                  name="select-delivery_type"
                  id="select-delivery_type"
                  onChange={(e) => {
                    changeLocation(e)
                  }}
                >
                  <option value={''}>Seleccione una ubicación</option>
                  {locations ? (locations.map((location) => {
                    const option = <option value={location}>{location}</option>
                    return option;
                  })) : null}
                </select>
            </div>
            </div>)
          }
            
            
            
          </Modal.Body>
          <Modal.Footer className="modal-footer-container">
          {ModalLoadingResponse ? null : <div className="row">
            <div className="col-6 pl-0"><button type="button" className="btn btn-secondary btn-block" onClick={hideModal}>Cancelar</button></div>
            <div className="col-6 pr-0"><button type="button" className="btn btn-primary btn-block" onClick={submitChangeLocation}>Cambiar ubicación</button></div>
            
            
            </div>
          }
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  return {
    response: state.documentDeliveryReducers.documentToDeliverySearchResponse.res,
    responseLocations: state.documentPackingReducers.documentLocationResponse.res,
    loadingResponse: state.documentDeliveryReducers.loadingScreen,
    loadingResponseLocations: state.documentPackingReducers.loadingScreen,
    responseChange : state.documentPackingReducers.changePreparationLocationResponse.res,
    ModalLoadingResponse: state.documentPackingReducers.ModalLoadingScreen,
  };
};

/*Export*/
export default connect(mapStateToProps, {
  PreparationTransferSearchAction,
  DocumentLocationAction,
  ChangePreparationLocationAction,
})(PreparationTransfer);
